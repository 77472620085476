import { addDays } from "date-fns";
import { useRouter } from "next/router";
import { useMemo } from "react";

import { useFeatureIsOn } from "components/Context/ExperimentContext";
import { useSession } from "components/Context/SessionContext";
import { ReservationStatus } from "gql/types";
import { ZonedDate } from "lib/date";

import {
  UseNavBarContextReservationFragment,
  useUseNavBarContextCloseReservationsQuery,
  useUseNavBarContextCurrentPurchaseQuery,
  useUseNavBarContextCurrentReservationQuery,
} from "./useNavBarContext.generated";

const useNavBarContext = (): NavBarContext => {
  const { pathname, query } = useRouter();
  const { session } = useSession();
  const standaloneCheckFF = useFeatureIsOn("standalone-key-navbar");

  const reservationId = pathname.startsWith("/myplace/reservation/[id]")
    ? query.id
    : null;
  const { data: dataReservation, isLoading: isLoadingReservation } =
    useUseNavBarContextCurrentReservationQuery(
      { reservationId: reservationId as string },
      { enabled: !!reservationId && standaloneCheckFF },
    );

  const purchaseId = pathname.startsWith("/myplace/purchase/[id]")
    ? query.id
    : null;
  const { data: dataPurchase, isLoading: isLoadingPurchase } =
    useUseNavBarContextCurrentPurchaseQuery(
      { purchaseId: purchaseId as string },
      { enabled: !!purchaseId && standaloneCheckFF },
    );

  const now = useMemo(() => ZonedDate.fromNow(ZonedDate.userTimezone()), []);
  const prevDays = now.fn(addDays, -7);
  const nextDays = now.fn(addDays, 7);

  const { data: dataCloseReservations, isLoading: isLoadingCloseReservations } =
    useUseNavBarContextCloseReservationsQuery(
      {
        filter: {
          stayWithin: {
            startTime: prevDays.date.toISOString(),
            endTime: nextDays.date.toISOString(),
          },
        },
      },
      {
        enabled:
          !!session?.inhabitantId &&
          !reservationId &&
          !purchaseId &&
          standaloneCheckFF,
      },
    );

  if (!standaloneCheckFF) {
    return { isStandaloneAccess: false };
  }

  if (dataReservation?.reservation) {
    return {
      isStandaloneAccess: hasStandalone(dataReservation.reservation),
      reservationId: dataReservation.reservation.id,
    };
  }

  if (dataPurchase?.purchase?.reservations) {
    return {
      isStandaloneAccess: dataPurchase.purchase.reservations.nodes.some(
        (reservation) => hasStandalone(reservation),
      ),
      reservationId: dataPurchase.purchase.reservations.nodes[0]?.id,
    };
  }

  if (dataCloseReservations?.reservations) {
    const checkedInReservation = dataCloseReservations.reservations.nodes.find(
      (r) => r.status === ReservationStatus.CheckedIn,
    );

    const closestConfirmedReservation = dataCloseReservations.reservations.nodes
      .sort((r1, r2) => r1.checkinTime.localeCompare(r2.checkinTime))
      .find((r) => r.status === ReservationStatus.Confirmed);

    const latestCheckedOutReservation = dataCloseReservations.reservations.nodes
      .sort((r1, r2) => r2.checkoutTime.localeCompare(r1.checkoutTime))
      .find((r) => r.status === ReservationStatus.CheckedOut);

    const reservation =
      checkedInReservation ||
      closestConfirmedReservation ||
      latestCheckedOutReservation;

    return {
      isStandaloneAccess: !!reservation && hasStandalone(reservation),
      reservationId: reservation?.id,
    };
  }

  return {
    isStandaloneAccess: false,
    isLoading:
      isLoadingReservation || isLoadingPurchase || isLoadingCloseReservations,
  };
};

const hasStandalone = (
  reservation: UseNavBarContextReservationFragment,
): boolean => {
  return (
    reservation.accesses?.some((access) =>
      access.openingMethods?.some(
        (openingMethod) =>
          openingMethod.__typename === "OpeningMethodStandalone",
      ),
    ) || false
  );
};

type NavBarContext = {
  isStandaloneAccess: boolean;
  reservationId?: string;
  isLoading?: boolean;
};

export default useNavBarContext;
